import { fun_GetAction } from "@/api";
export default {
  data: () => {
    return {
      data: [], // 列表
      info: {} // 信息
    };
  },
  mounted() {
    this.fun_GetList();
  },
  methods: {
    /* 列表 */
    async fun_GetList() {
      // 请求接口
      let res = await fun_GetAction(
        "/projectInfo/bidOpeningTodayDetail?projectId=" + this.$route.query.id
      );
      // 声明
      const { result, data } = res.data;
      // 判断请求结果
      if (result) {
        // 赋值列表数据
        this.data = data.list;
        this.info = data.projectInfoEntity;
      }
    },
    /* 刷新 */
    fun_SyncResult() {
      this.fun_GetList();
    },
    /* 返回 */
    fun_GoBack() {
      this.$router.go(-1);
    }
  }
};
