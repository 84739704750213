var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"open-hall"},[_c('header',[_c('div',{staticClass:"info-wrap"},[_c('div',{staticClass:"info-row"},[_c('div',{staticClass:"info-item"},[_vm._v("项目名称："+_vm._s(_vm.info.projectName))]),_c('div',{staticClass:"info-item"},[_vm._v("项目编号："+_vm._s(_vm.info.projectNo))]),_c('div',{staticClass:"info-item"},[_vm._v("标段/分包："+_vm._s(_vm.info.bidSection))])]),_c('div',{staticClass:"info-row"},[_c('div',{staticClass:"info-item"},[_c('span',[_vm._v("开标时间："+_vm._s(_vm.info.meetingStartDate))]),_c('span',[_vm._v(" 解密开始时间："+_vm._s(_vm.info.decryptStarTime))])]),_c('div',{staticClass:"info-item"},[_vm._v("解密截止时间："+_vm._s(_vm.info.decryptEndTime))]),_c('div',{staticClass:"info-item"},[_vm._v(" 开标状态： "),_c('span',{style:({
              color:
                _vm.info.state === '正在进行'
                  ? '#0ADB76'
                  : _vm.info.state === '未开标'
                  ? '#FFBA00'
                  : '#CABEBE'
            })},[_vm._v(_vm._s(_vm.info.state))])])])])]),_c('section',[_c('div',{staticClass:"tool-wrap"},[_c('span',{staticClass:"title-text"},[_vm._v("开标记录")]),_c('div',[_c('span',{staticClass:"title-btn",on:{"click":_vm.fun_SyncResult}},[_vm._v("手动同步开标结果")]),_c('span',{staticClass:"title-btn back-btn",on:{"click":_vm.fun_GoBack}},[_vm._v("返回")])])]),_c('div',{staticClass:"content-wrap"},[_c('table',{staticStyle:{"border-collapse":"collapse"}},[_c('tbody',[_vm._m(0),_vm._l((_vm.data),function(e,i){return _c('tr',{key:i,attrs:{"align":"center"}},[_c('td',{attrs:{"title":e.corpName}},[_vm._v(_vm._s(e.corpName))]),_c('td',{attrs:{"title":e.biddingPrice}},[_vm._v(_vm._s(e.biddingPrice))]),_c('td',{attrs:{"title":e.biddingPeriod}},[_vm._v(_vm._s(e.biddingPeriod))]),_c('td',{attrs:{"title":e.projectManager}},[_vm._v(_vm._s(e.projectManager))]),_c('td',{attrs:{"title":e.managerCertno}},[_vm._v(_vm._s(e.managerCertno))]),_c('td',{attrs:{"title":e.bidSecurityInfo}},[_vm._v(_vm._s(e.bidSecurityInfo))]),_c('td',{attrs:{"title":e.biddingSource}},[_vm._v(_vm._s(e.biddingSource))]),_c('td',{attrs:{"title":e.bidState}},[_vm._v(_vm._s(e.bidState))]),_c('td',{attrs:{"title":e.bidGuarantee}},[_vm._v(_vm._s(e.bidGuarantee))])])})],2)])])]),_vm._m(1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',{attrs:{"align":"center"}},[_c('th',{attrs:{"width":"200px"}},[_vm._v("投标人")]),_c('th',{attrs:{"width":"150px"}},[_vm._v("投标报价（万元）")]),_c('th',{attrs:{"width":"70px"}},[_vm._v("投标工期")]),_c('th',{attrs:{"width":"80px"}},[_vm._v("项目经理/负责人")]),_c('th',{attrs:{"width":"100px"}},[_vm._v("项目经理/负责人证书编号")]),_c('th',{attrs:{"width":"80px"}},[_vm._v("投标保证金")]),_c('th',{attrs:{"width":"80px"}},[_vm._v("递交主通道")]),_c('th',{attrs:{"width":"80px"}},[_vm._v("备用通道")]),_c('th',{attrs:{"width":"100px"}},[_vm._v("开标保障服务")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('footer',[_c('p',[_vm._v(" Copyright © 2014-2015 武汉招投评标系统 版权所有 | 技术服务：武汉墨仗信息科技股份有限公司 ")])])
}]

export { render, staticRenderFns }